<template>
	<div class="container page">
		<van-nav-bar title="Pencatatan tugas" class="nav-bar">
			<template #left>
				<van-icon name="arrow-left" color="#fff" @click="back()" />
			</template>
		</van-nav-bar>
		<div class="main">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
				<van-empty v-if="list.length === 0" description="Data kosong！" />
				<div v-else class="item_list" v-for="(v,key) in list" :key="key">
					<div class="lottery_info">
						<van-image class="cover" :src="v.ico">
							<template v-slot:loading>
								<van-loading type="spinner" />
							</template>
						</van-image>
						<span class="period-number">{{v.expect}}</span>

					</div>
					<p class="period-number" style="margin: 0px;margin-left: 12.667vw;">{{v.name}}</p>
					<div class="recent">
						<div class="kuaisan-ball left">
							<span
								class="res-des middle">{{v.status === 0 ? '***' : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "A" : "B"}}</span>
							<span
								class="res-des middle">{{v.status === 0 ? '***' : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? "D" : "C"}}</span>
						</div>
					</div>
					<div class="topInfo" style="display: grid;">
						<span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
						<span v-else>{{v.status_text}}</span>
						<span>Jumlah taruhan：{{v.money}}Rp</span>
					</div>
					<div v-for="(vs,indexs) in v.list" :key="indexs" style="border-bottom: 1px #e3e3e3 solid;">
						<div class="time">
							<span>Jenis tugas：</span>
							<span style="float: none;color: #000;font-size: 16px;">{{vs.money}}</span>
						</div>
						
						<div class="time">
							<span>Jumlah taruhan tunggal：</span>
							<span style="float: none;color: #000;font-size: 16px;font-weight: 600;">{{vs.type}}</span>
						</div>
						
						<div class="time" v-if="vs.is_win < 1">
							<span>Detail keuntungan: {{vs.win_text}}</span>
						</div>
						<div class="time" v-else>
							<span v-if="vs.is_win === 2"> Detail keuntungan: <span
									style="color: #ff0202;box-sizing: border-box;font-size: 16px;font-weight: bold;">-
									{{vs.profit}} Rp</span></span>
							<span v-else>Detail keuntungan: <span
									style="color: #00973a;box-sizing: border-box;font-size: 16px;font-weight: bold;">+
									{{vs.profit}} Rp</span></span>
						</div>
						<div class="time">
							<span>Waktu ketika pesanan dilakukan：{{vs.create_time}}</span>
						</div>
						
						
					</div>
					<div class="time">
						<span>Waktu penyelesaian：{{v.update_time}}</span>
					</div>
					
					
				</div>

			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				list: [],
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast('Penyegaran berhasil');
					this.isLoading = false;
				}, 500);
			},
			getUserGameList() {
				this.$http({
					method: 'get',
					url: 'user_get_game_list'
				}).then(res => {
					if (res.code === 200) {
						console.log(res.data)
						this.list = res.data;
					} else if (res.code === 401) {
						this.$toast(res.msg);
					}
				})
			}

		},
		created() {
			if (!localStorage.getItem('token')) {
				this.$router.push({
					path: '/Login'
				})
			} else {
				this.getUserGameList();
			}
		}
	};
</script>

<style lang='less' scoped>
	@import "../../assets/css/base.css";

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}

	::v-deep .van-loading__text {
		color: #000000;
		font-size: 35px;
	}

	.container .main {
		position: relative;
		overflow: auto;
		background-color: #f2f2f5;
		height: 100%;
		padding: 0 10px;
	}

	.item_list {
		padding: 15px 15px;
		margin: 30px 10px;
		background: #fff;
		border-radius: 10px;
		line-height: 60px;
	}

	.item_list .topInfo span {
		flex: 1;
		font-size: 35px;
		font-weight: 700;
		color: #ff253f;
	}

	.item_list .time span {
		flex: 1;
		font-size: 25px;
		font-weight: 500;
		color: #000;
	}

	.item_list .topInfo span:last-child {
		float: right;
	}

	.item_list .desc span {
		font-size: 25px;
		font-weight: 700;
		color: #9b9b9b;
	}

	.item_list .cover {
		width: 60px;
		height: 60px;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.item_list .period-number {
		margin-left: 50px;
		margin-right: 10px;
		height: 50px;
		line-height: 60px;
		font-size: 35px;
		font-weight: 700;
		color: #000;
	}

	.item_list .lottery_info {
		display: flex;
	}

	.recent {
		display: flex;
		align-items: center;
		height: 100px;
	}

	.kuaisan-ball .left {
		justify-content: flex-start;
	}

	.kuaisan-ball {
		flex: 1;
		display: flex;
		align-items: center;
	}

	.kuaisan-ball .res-img {
		width: 70px;
		height: 70px;
		margin-right: 30px;
	}

	.kuaisan-ball .res-des {
		font-weight: 700;
		text-align: center;
		color: #000;
	}

	.kuaisan-ball .res-des.middle {
		width: 15%;
		font-size: 35px;
	}
</style>